import { createSlice } from '@reduxjs/toolkit';

const newsSlice = createSlice({
  name: 'news',
  initialState: {
    news: {},
    highlightedNews: null,
  },
  reducers: {
    setNews(state, action) {
      if(!action.payload.team){
        console.error('no team for news')
      }
      else{
        // if(action.payload.team === 'ALL'){
        //   state.news['highlights'] = action.payload.news.slice(0,3);
        //   state.news['showcase'] = action.payload.news.slice(3);
        // }
        state.news[action.payload.team] = action.payload.news;
      }
    },
    setHighlightedNews(state, action) {
      state.highlightedNews = action.payload;
    },
  },
});

export const { setNews, setHighlightedNews } = newsSlice.actions;

export default newsSlice.reducer;