import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  doc,
  getDoc,
  getDocs,
  limit,
  startAfter,
  setDoc,
  serverTimestamp,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import {
  HEROKU_AUTHORIZATION,
  HEROKU_GET_USER_BY_DOC,
  HEROKU_ADD_AND_INVITE,
  HEROKU_GET_REGULATIONS,
  HEROKU_GET_RECORD_TYPE,
  HEROKU_GET_SOCIO_NUMBER,
  HEROKU_GET_USER_INFO_BEFORE_LOGIN,
} from "./URLconsts";
import {
  NEWS_ARTICLE_URL,
  NEWS_ALTERNATIVE_URL,
  NEWS_SITE_ALTERNATIVE__URL,
  NEWS_PREVIEW_URL,
  TEAMS,
  COLLECTIONS,
} from "./consts";
import moment from "moment";
import ToolHelper from "./ToolHelper";
import audienceHelper from "./AudienceHelper";
import CruzeiroApi from "./CruzeiroApi";
const now = Timestamp.now();

export default class DataHelper {
  constructor(app, db, getConfigFile) {
    this.app = app;
    this.db = db;
    

    getConfigFile(`utils/DataHelper/config.js`).then((importedFile) => {
      this.customCfg = importedFile?.default;
    });
    
  }

  setUserHelper(userHelper) {
    this.userHelper = userHelper;
  }

  getList(snapshot) {
    let list = [];

    snapshot.forEach((doc) => {
      list.push(doc.data());
    })

    return list;
  }

  async getCurrentSeason(){
    const currentSeason = await getDoc(doc(this.db, COLLECTIONS.APPSETTINGS, 'CurrentSeason'));
    var data = currentSeason.data().TextValue__c;
    return data;
  };

  async snapPlayersSeason(callback) { 
    const season = await this.getCurrentSeason();
    const seasonObj = JSON.parse(season);
    let playersQ = query(collection(this.db, 'PlayerSeason'),
     where('Player__r.Active__c', '==', true),
     where('Season__c', '==', seasonObj.Id));

    let unsub = onSnapshot(playersQ, async (snapshot) => {
      let players = this.getList(snapshot); 
      callback(players);
    })

    return { players: unsub }
  }

  async snapBanners(callback) {
    let bannersQ = query(collection(this.db, 'AdvertisingArea'), where('Active__c', '==', true));
    let userData = await this.userHelper.getUser();
    //let isConsultant = this.userHelper.isConsultant(userData);
    let hasUserLogged = !!userData;

    let unsub = onSnapshot(bannersQ, async (snapshot) => {
      let banners = this.getList(snapshot);

      if (banners?.length) {
        const compareOrder = (a, b) => {
          if (!a?.Order__c) return 1;
          if (!b?.Order__c) return -1;

          if (a.Order__c < b.Order__c)
            return -1;

          if (a.Order__c > b.Order__c)
            return 1;

          return 0;
        }
        

       //banners = banners.filter(banner => !hasUserLogged ? banner.Location__c === 'Home Deslogada' : banner.Location__c !== 'Home Deslogada')

        // if has user logged
        if (!!hasUserLogged) {
          const userCTG = await this.userHelper.getCTGUser();

          banners = banners.map(banner => {
            if (banner?.AdvertisingItems__r?.records) {
              banner.AdvertisingItems__r.records.sort(compareOrder)
            }

            let name = banner.Name.toLowerCase();

            return banner;
          }).filter(Boolean)

          //filtro para a História Banner 2.0 (Sprint 7)
          // banners = banners.reduce((group, banner) => {
          //   if (banner.acg_AudienceType__c?.toLowerCase() === 'all') {
          //     group = [...group, banner]
          //   } else if (banner.acg_AudienceType__c?.toLowerCase() === 'custom') {
          //     if (banner.acg_CustomField__c !== null) {
          //       if (banner.acg_CustomField__c?.toLowerCase().indexOf("profile") !== -1) {
          //         if (banner.acg_LoyaltyProfile__c !== null && userData.acg_LoyaltyProfiles__c !== null) {
          //           let profiles = banner.acg_LoyaltyProfile__c?.split(';')
          //           let userProfiles = userData.acg_LoyaltyProfiles__c?.split(';')
          //           if (profiles) {
          //             for (let idx in profiles) {
          //               if (userProfiles?.includes(profiles[idx])) {
          //                 group = [...group, banner]
          //                 break;
          //               }
          //             }
          //           }
          //         }
          //       } else if (banner.acg_CustomField__c?.toLowerCase().indexOf("category") !== -1) {
          //         if (banner.acg_LoyaltyCategory__c !== null && userData.LoyaltyCategory__c !== null) {
          //           let categories = banner.acg_LoyaltyCategory__c?.split(';')
          //           let userCategories = userData.LoyaltyCategory__c?.split(';')
          //           if (categories) {
          //             for (let idx in categories) {
          //               if (userCategories?.includes(categories[idx])) {
          //                 group = [...group, banner]
          //                 break;
          //               }
          //             }
          //           }
          //         }
          //       }
          //     }
          //   } else if (banner.acg_AudienceType__c?.toLowerCase() === 'ctg') {
          //     for (let idx in userCTG) {
          //       if (userCTG[idx].CampaignId === banner.acg_CTG__c) {
          //         group = [...group, banner]
          //       }
          //     }
          //   }

          //   return group
          // }, []);
        }
      }

      callback(banners);
    })

    return { banners: unsub }
  }
  snapNews(callback, location) {
    let newsQ = query(
      collection(this.db, location.includes("/imprensa/") ? "Imprensa" : "News"),
      where("Active__c", "==", true),
      // where("Device__c", "in", ["Web", "All"]),
      where("IsApproved__c", "==", true),
      where("PublishDateTimestamp", "<=", now),
      orderBy("PublishDateTimestamp", "desc"),
      limit(9)
    );

    let unsub = onSnapshot(newsQ, (snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    });

    return { news: unsub };
  }
  snapHighlightedNews(callback) {
    let newsQ = query(
      collection(this.db, "News"),
      where("Active__c", "==", true),
      where("IsApproved__c", "==", true),
      // where("Device__c", "in", ["Web", "All"]),
      where("Highlighted__c", "==", true),
      where("PublishDateTimestamp", "<=", now),
      orderBy("PublishDateTimestamp", "desc"),
      limit(9)
    );

    let unsub = onSnapshot(newsQ, (snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    });

    return { highlightedNews: unsub };
  }
  snapYTVideos(callback) {
    let newsQ = query(
      collection(this.db, "Youtube"),
      where("Active__c", "==", true),
      where("IsApproved__c", "==", true),
      // where("Device__c", "in", ["Web", "All"]),
      where("PublishDateTimestamp", "<=", now),
      orderBy("PublishDateTimestamp", "desc"),
      limit(9)
    );

    let unsub = onSnapshot(newsQ, (snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    });

    return { ytvideos: unsub };
  }
  snapConfig(config, callback) {
    let configQ = query(collection(this.db, config.table));

    if (config.where?.length) {
      config.where.map(condition => {
        configQ = query(configQ, where(condition[0], condition[1], condition[2]))
      })
    }

    if (config.order?.length) {
      config.order.map(order => {
        configQ = query(configQ, orderBy(order[0], order[1]))
      })
    }

    let unsub = onSnapshot(configQ, (snapshot) => {
      let list = this.getList(snapshot);
      callback(list);
    })

    return unsub
  }
  async getNews(qty = 10, startAfterDoc = null, isPreview = false) {
    let newsQ = query(
      collection(this.db, "News"),
      // where("Device__c", "in", ["Web", "All"]),
      where("PublishDateTimestamp", "<=", now),
      orderBy("PublishDateTimestamp", "desc"),
      limit(qty)
    );

    if (!isPreview) {
      newsQ = query(
        newsQ,
        where("Active__c", "==", true),
        where("IsApproved__c", "==", true)
      );
    }
    
    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      });
    }

    return { data, lastDoc };
  }

  async getPressNews(qty = 10, startAfterDoc = null) {
    let newsQ = query(
      collection(this.db, "Imprensa"),
      where("Active__c", "==", true),
      // where("Device__c", "in", ["Web", "All"]),
      where("PublishDateTimestamp", "<=", now),
      where("IsApproved__c", "==", true),
      orderBy("PublishDateTimestamp", "desc"),
      limit(qty)
    );

    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }

    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data, lastDoc };
  }
  async getNewsByTeam(selectedTeam, qty = 10, startAfterDoc = null, isPreview = false) {
    let newsQ = query(
      collection(this.db, "News"),
      // where("Device__c", "in", ["Web", "All"]),
      where("PublishDateTimestamp", "<=", now),
      orderBy("PublishDateTimestamp", "desc"),
      limit(qty)
    );

    if (!isPreview) {
      newsQ = query(
        newsQ,
        where("Active__c", "==", true),
        where("IsApproved__c", "==", true)
      );
    }

    if (selectedTeam !== 'ALL') {
      newsQ = query(newsQ, where('Team__c', '==', selectedTeam));
    }

    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data, lastDoc };
  }
  async getYTVideos(qty = 10, startAfterDoc = null, isPreview = false) {
    let newsQ = query(
      collection(this.db, "Youtube"),
      // where("Device__c", "in", ["Web", "All"]),
      where("PublishDateTimestamp", "<=", now),
      orderBy("PublishDateTimestamp", "desc"),
      limit(qty)
    );

    if (!isPreview) {
      newsQ = query(
        newsQ,
        where("Active__c", "==", true),
        where("IsApproved__c", "==", true)
      );
    }

    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      });
    }

    return { data, lastDoc };
  }
  async getArticle(articleId, isPreview = false, isRelease = false) {
    let articleDoc;

    let defaultArticleFullPath = NEWS_ARTICLE_URL.replace(
      "[ARTICLEID]",
      articleId
    );
    let alternativeArticleFullPath = NEWS_ALTERNATIVE_URL.replace(
      "[ARTICLEID]",
      articleId
    );

    let siteAlternativeArticleFullPath = NEWS_SITE_ALTERNATIVE__URL.replace(
      "[ARTICLEID]",
      articleId
    );

    let articleQ = query(
      collection(this.db, isRelease ? "Imprensa" : "News"),
      where("Url__c", "in", [
        defaultArticleFullPath,
        alternativeArticleFullPath,
        siteAlternativeArticleFullPath,
      ]),
    );
    
    if (!isPreview) {
      articleQ = query(
        articleQ,
        where("Active__c", "==", true),
        where("IsApproved__c", "==", true),
        where("PublishDateTimestamp", "<=", now)
      );
    }

    let deviceDocs = await getDocs(articleQ);
    let deviceResults = deviceDocs?.docs;

    let filteredResults = deviceResults.filter((doc) => {
      let data = doc.data();

      // return ["Web", "All"].includes(data?.Device__c);
      return data;
    });

    if (filteredResults?.length) {
      articleDoc = filteredResults[0];
    }

    if (articleDoc) {
      return articleDoc.data();
    }

    return false;
  }
  async getAppSettingText(textName, callback) {
    let textQ = doc(this.db, 'AppSetting', textName);
    let textDoc = await getDoc(textQ);

    if (textDoc?.exists() && textDoc?.data()['TextValue__c']) {
      callback(textDoc.data()['TextValue__c']);
    }
    else {
      callback('');
    }
  }

  async audienceTypeValidation(id, user) {
    let audiencesQ = query(collection(this.db, 'AudienceType'), where('Id', '==', id));
    let audienceDocs = await getDocs(audiencesQ);

    const userCTG = await this.userHelper.getCTGUser();

    const compare = (record) => {
      let ret = false;
      if (record.Type__c === 'Campaign' && userCTG) {
        let hasCampaing = userCTG.filter(ctg => ctg.CampaignId === record.Campaign__c);
        if (hasCampaing && hasCampaing.length > 0) {
          ret = true;
        }
      } else if (record.Type__c === 'Field') {
        if (record.Comparator__c === '=') {
          if (user[record.Field__c] === record.Value__c) {
            ret = true;
          }
        } else if (record.Comparator__c?.toString().toLowerCase() === 'in') {
          let values = record.Value__c?.split(';')
          let userProfiles = user.acg_LoyaltyProfiles__c?.split(';');
          if (values) {
            for (let idx in values) {
              if (userProfiles?.includes(values[idx])) {
                ret = true;
                break;
              }
            }
          }
        }
      }

      return ret;
    }

    if (audienceDocs.docs.length) {
      let audienceDoc = audienceDocs.docs[0].data();
      let ret = false;

      if (!audienceDoc.AudienceTypeCriterias__r?.records) {
        return ret;
      }

      if (audienceDoc.CriteriaType__c === 'OR') {
        audienceDoc.AudienceTypeCriterias__r?.records.forEach(x => {
          if (compare(x)) {
            ret = true;
            return;
          }
        })
      } else {
        ret = true;
        audienceDoc.AudienceTypeCriterias__r?.records.forEach(x => {
          if (!compare(x)) {
            ret = false;
            return
          }
        })
      }
      return ret;
    }
  }

  async getTermsText(callback) {
    this.getAppSettingText('TermsOfService', callback);
  }

  async getHerokuDomainApi() {
    let urlQ = doc(this.db, 'AppSetting', 'HerokuApi');
    let urlDoc = await getDoc(urlQ);

    if (urlDoc.exists() && urlDoc.data().TextValue__c) {
      const { url, token } = JSON.parse(urlDoc.data().TextValue__c || '{}')

      return { url, token };
    }

    return false;
  }

  async getHerokuDomain() {
    // return 'http://localhost:5000'; // uat

    let urlQ = doc(this.db, 'AppSetting', 'HerokuUrl');
    let urlDoc = await getDoc(urlQ);

    if (urlDoc.exists() && urlDoc.data().TextValue__c) {
      return urlDoc.data().TextValue__c;
    }

    return false;
  }

  async getHerokuUrl(path, isRest = true) {
    if (!global.herokuDomain) {
      global.herokuDomain = await this.getHerokuDomain();
    }

    let url = '';

    if (global.herokuDomain) {
      url = global.herokuDomain

      if (isRest) {
        url += '/restHelper';
      }

      url += path;
    }

    return url;
  }

  async loadCurrentSeasonId() {
    let seasonQ = doc(this.db, 'AppSetting', 'CurrentSeason');
    let seasonDoc = await getDoc(seasonQ);

    let season = '';

    if (seasonDoc.exists() && seasonDoc.data().TextValue__c) {
      season = JSON.parse(seasonDoc.data().TextValue__c)?.Id;
    }

    global.season = season;

    return season;
  }
  async uploadImage(img, path, imageId) {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}/${imageId}.jpeg`);
    const metadata = { contentType: 'image/jpeg' };

    const snapshot = await uploadBytes(storageRef, img, metadata);
    const url = await getDownloadURL(storageRef);

    return url;
  }

  async deleteImage(path, imageId) {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}/${imageId}.jpeg`);

    try {
      await deleteObject(storageRef);
      return true;
    }
    catch (e) {
      console.log('erro deletando imagem!', e);
      return false;
    }
  }

  dateDisplayFormat(date) {
    if (date?.length && date?.search('-') !== -1) {
      return date.split('-').reverse().join('/')
    }

    return date;
  }

  appScreenToUrl(routes, appScreen) {
    switch (appScreen) {
      case 'Profile':
        return routes.profile.path;
      case 'Survey':
        return routes.survey.path;
      case 'ReceiptHistory':
        return routes.home.path;
      default:
        return routes.home.path;
    }
  }

  nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }

  docsToArray(elDocs) {
    let data = [];

    if (elDocs.docs.length) {
      data = elDocs.docs.map((doc) => {
        return doc.data();
      })
    }

    return data;
  }
  userByDoc = async (doc) => {
    if(!doc) {
      return false;
    }

    const getUserUrl = await this.getHerokuUrl(`${HEROKU_GET_USER_BY_DOC}/${doc}`, false);

    try {
      let response = await fetch(getUserUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
      });

      if (response.ok) {
        const parsedResponse = await response.json();
        return parsedResponse;
      } else {
        return { message: 'erro ao trazer usuário!', error: true }
      };
    }
    catch (e) {
      console.log('error', JSON.stringify(e));
      return false;
    };
  }

  async hasActiveProfiles(userGroupId) {
    if(!userGroupId) {
      return false;
    }

    const profiles = await this.getProfilesByUserGroup(userGroupId);

    if (profiles && profiles?.length) {
      return profiles.some((u) => !["Inativo", "Blocked"].includes(u.acg_Status__c))
    }

    return false;
  }

  async getUserInfoBeforeLogin(firebaseId) {
    if(!firebaseId) {
      return false;
    }

    const getUserInfoBeforeLoginUrl = await this.getHerokuUrl(`${HEROKU_GET_USER_INFO_BEFORE_LOGIN}/${firebaseId}`, false);

    try {
      const response = await fetch(getUserInfoBeforeLoginUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async snapStartUpMessages() {
    let elQ = query(collection(this.db, 'StartUpMessages'), where('Active__c', '==', true), orderBy('Priority__c', 'asc'));
    let elDoc = await getDocs(elQ);

    let data = [];

    if (elDoc.docs.length) {
      data = elDoc.docs.map((doc) => {
        return doc.data();
      })
    }

    return { data };
  }

  async getHeaderMessage(){
    var currentDateTime = ToolHelper.getISO8601Date();

    // Firebase não deixa filtrar duas inequalidades em campos diferentes. 
    // Por isso é filtrado apenas data futura. A data de início é filtrada localmente
    let elQ = query(
      collection(this.db, 'StartUpMessages'), 
      where('ScreenType__c', '==', 'Site Header'),
      where('EndDateTime__c','>=',currentDateTime),
      orderBy('EndDateTime__c', 'asc'),
      orderBy('Priority__c', 'asc')
    );

    let elDoc = await getDocs(elQ);

    let data;

    if (elDoc.docs.length) {
      elDoc.docs.forEach(doc => {
        let docData = doc.data();

        if(docData.StartDateTime__c <= currentDateTime){
          // Se houver mais de um substitui. E isso está ok pois Priority__c maior é mais prioritário
          data = docData;
        }
      })
    }

    return data;
  }

  async getSocialMedias(category = 'Masc'){
    let mediasRef = doc(this.db, 'AppSetting', 'SocialMediaCategoriesSettings');
    let mediasDoc = await getDoc(mediasRef);
    let result = [];

    if (mediasDoc.exists()) {
      let text = mediasDoc.data().TextValue__c;

      let obj = JSON.parse(text);
      result = obj[category]
    }

    return result;
  }

  async getTeamTitles(category) {
    let titles;

    if (!category) {
      titles = query(
        collection(this.db, "Gallery"),
        where("Name", "==", "Conquistas"),
        where("Active__c", "==", true),
        where("Device__c", "==", "Web")
      );
    } else {
      titles = query(
        collection(this.db, "Gallery"),
        where("Team__c", "==", category),
        where("Name", "==", "Conquistas"),
        where("Active__c", "==", true),
        where("Device__c", "==", "Web")
      );
    }

    const titlesDoc = await getDocs(titles);

    let data = [];

    if (titlesDoc.docs.length) {
      data = titlesDoc.docs.map((doc) => {
        return doc.data();
      });
    }

    return data[0];
  }
  
 async getCaseSettings() {
    let caseSettingsRef = doc(this.db, 'AppSetting', 'CaseSettings');
    let caseSettingsDoc = await getDoc(caseSettingsRef);
    let result = {};

    if(caseSettingsDoc.exists()) {
      let text = caseSettingsDoc.data().TextValue__c;
      let obj = JSON.parse(text);
      result = obj;
    }

    return result;
  }

  async getEvents(selectedTeam = "CRU",qtyPast = 2,qtyFuture = 5){
    let elQ = query(
      collection(this.db, 'Event'), 
    );

    switch(selectedTeam){
      case 'BASE': case 'CRUBASE':
        elQ = query(elQ,where('Team__c','in',['CRU17','CRU20']))
      break;
      case 'ALL':
      break;
      default:
        elQ = query(elQ,where('Team__c','==',selectedTeam))
    }

    // Buscando primeiro 2 eventos passados
    var currentDateTime = ToolHelper.getISO8601Date();
    let elQPast = query(elQ,
      where('EventDate__c','<',currentDateTime),
      orderBy('EventDate__c','desc'),
      limit(qtyPast)
    )

    let elDocPast = await getDocs(elQPast);

    let data = [];

    if (elDocPast.docs.length) {
      elDocPast.docs.forEach(doc => {
        let docData = doc.data();
        data.push(docData);
      })


      data = data.reverse();
    }

    // Agora busca 5 eventos futuros
    let elQFuture = query(elQ,
      where('EventDate__c','>=',currentDateTime),
      orderBy('EventDate__c','asc'),
      limit(qtyFuture)
    )

    let elDocFuture = await getDocs(elQFuture);

    if (elDocFuture.docs.length) {
      elDocFuture.docs.forEach(doc => {
        let docData = doc.data();
        data.push(docData);
      })
    }

    return data;
  }

  // Idealmente esse label deve ser extraído do SF, por hora está fixo
  getTeamLabel(team){
    let officialTeamTag = this.findTeamTag(team);

    if(TEAMS[officialTeamTag]){
      return TEAMS[officialTeamTag].label;
    }

    return '';
  }

  // ex1: recebe CRU17, retorna CRUBASE
  // ex2: recebe CRUFEM, retorna CRUFEM
  findTeamTag(team){
    if(TEAMS[team])
      return team;

    for (const [teamKey, teamProps] of Object.entries(TEAMS)) {
      if(teamProps.alias?.length){
        if(teamProps.alias.includes(team)){
          return teamKey;
        }
      }
    }
  }

  // recebe CRU, retorna CRU
  // recebe CRUFEM, retorna CRU
  // recebe PAL, retorna PAL
  defaultTeamCode(teamCode){
    if(!teamCode?.length)
      return '';

    return teamCode.startsWith('CRU') ? 'CRU' : teamCode;
  }

  getTeamColor(team) {
    let officialTeamTag = this.findTeamTag(team);

    if(TEAMS[officialTeamTag]){
      return {
        color: TEAMS[officialTeamTag].colors.text,
        backgroundColor: TEAMS[officialTeamTag].colors.bg,
        borderColor: TEAMS[officialTeamTag].colors.border,
      }
    }

    return {};
  }

  async getContent(contentId) {
    // Busca conteúdo pelo path
    let caseSettingsRef = doc(this.db, 'Article', contentId);
    let caseSettingsDoc = await getDoc(caseSettingsRef);
    let result = {};

    if(caseSettingsDoc.exists()) {
      result = caseSettingsDoc.data()
    }

    return result;
  }

  async snapAudienceTypes(callback) {
    try {
      // const userRef = await this.userHelper.getUserRef();
      let userData = await this.userHelper.getUser();
      const audienceRef = collection(this.db, 'AudienceType');
      const snapshot = await getDocs(audienceRef);
      const audienceData = this.getList(snapshot);
      
      if (!userData) {
        userData = {
          LoyaltyCategory__c: 'NAÇÃO AZUL',
        }
      }  
      
      // const campaign = await getDocs(collection(userRef, "CampaignMember"));
   
      // const userCampaign = {};
      // this.getList(campaign).forEach(
      //   (item) => (userCampaign[item.data().CampaignId] = item.data())
      // );
      // console.log('segunda fase', userCampaign);

      const audienceTypes = await audienceHelper.getAudienceTypes(
        audienceData,
        userData,
        // userCampaign
      );
    
      callback(audienceTypes)    
    } catch (error) {
      console.error("Erro ao recuperar tipos de audiência:", error);
      throw error;
    }
  }

  async snapMenuItems(callback) {
    let menuItemsQ = query(collection(this.db, 'MenuItem'),
                      orderBy('Order__c', 'asc'));

    let unsub = onSnapshot(menuItemsQ, async (snapshot) => {
      let menuItems = this.getList(snapshot);

      callback(menuItems);
    })

    return { menuItems: unsub }
  }

  async snapSocialMedias(callback){
    const docSocialMediaItems = doc(this.db, 'AppSetting', 'SocialMediaCategoriesSettings');

    let unsub = onSnapshot(docSocialMediaItems, async (snapshot) => {

      if (snapshot?.exists() && snapshot?.data()['TextValue__c']) {
        let text = snapshot.data()['TextValue__c'].replace(/(\r\n|\n|\r)/gm, "");
        callback(JSON.parse(text));
      } else {
        console.log('Social medias não encontradas');
      }
    })

    return { socialMediaItems: unsub }
  }

  async getKnowledges(callback, type) {
    const userData = await this.userHelper.getUser();
    let knowledgeRef;
    if (userData?.LoyaltyCategory__c === "Staff") {
      knowledgeRef = query(
        collection(this.db, "Knowledges", type, 'records'), where("IsVisibleInCsp", "==", true)
      );
    } else {
      try {
        knowledgeRef = query(
          collection(this.db, "Knowledges", type, 'records'),
            where("PublishStatus__c", "==", "Live"),
            where("Language", "==", "pt_BR"),
            where("IsVisibleInCsp", "==", true),
        );
      } catch (error) {
        console.log("error", error);
      }
    }

    let unsub = onSnapshot(knowledgeRef, async (snapshot) => {
      callback(snapshot);
    });

    return { knowledge: unsub };
  }

  async getMidiaSettings() {
    let midiaSettingsRef = await getDoc(doc(this.db, COLLECTIONS.APPSETTINGS, 'MidiaSettings'));
    let data = midiaSettingsRef.data().TextValue__c
    return JSON.parse(data);
  }

  async getSocioNumber() {
    try {
      const herokuDomain = await this.getHerokuUrl(HEROKU_GET_SOCIO_NUMBER);
      
      const response = await fetch(herokuDomain, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: HEROKU_AUTHORIZATION
        },
      });

      if (response?.ok) {
        const socioNumber = await response.json();
        return socioNumber;
      } else {
        console.log("error getting socio number");
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  async getRedirectMember(callback) {
    const redirectMemberItem = doc(this.db, COLLECTIONS.APPSETTINGS, 'RedirectMember');

    let unsub = onSnapshot(redirectMemberItem, async (snapshot) => {
      if (snapshot?.exists()) {
        let text = snapshot.data().TextValue__c
        callback(JSON.parse(text));
      } 
    })

    return { redirectMembership: unsub }
  }

  async getNewMembershipPlans (callback) {
    const NewMembershipPlansRef = doc(this.db, COLLECTIONS.APPSETTINGS, 'NewMembership');

    let unsub = onSnapshot(NewMembershipPlansRef, async (snapshot) => {
      if (snapshot?.exists()) {
        let text = snapshot.data().TextValue__c
        callback(JSON.parse(text));
      } 
    })

    return { newMembership: unsub }
  }

  async disableLogin(callback) {
    const disableLoginItem = doc(this.db, COLLECTIONS.APPSETTINGS, 'DisableLogin');

    let unsub = onSnapshot(disableLoginItem, async (snapshot) => {
      if (snapshot?.exists()) {
        let text = snapshot.data().TextValue__c
        callback(JSON.parse(text));
      } 
    })

    return { disableLogin: unsub }
  }

  getBenefitsToShow(callback) {
    const benefitsToShowRef = doc(this.db, COLLECTIONS.APPSETTINGS, 'BenefitsToShow');

    let unsub = onSnapshot(benefitsToShowRef, (snapshot) => {
      if (snapshot?.exists()) {
        let text = snapshot.data()?.TextValue__c
        callback(JSON.parse(text || "[]"));
      } 
    })

    return { benefitsToShow: unsub }
  }
  
  async handleGoToSocio5estrelas (user, dataHelper, redirect_uri = '/planos') {
    const result = await CruzeiroApi.socioSSO({
      user,
      plan: '',
      dataHelper,
      redirect_uri
    });

    if (result?.success) {
      window.open(result.url, '_blank');
    } else {
      window.open('https://socio5estrelas.com.br/?utm_source=site_oficial&utm_campaign=InfluencerAqui&utm_content=IconTopoSite', '_blank');
    }
  }
}
