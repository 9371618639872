import FormHelper from "../components/utils/FormHelper";

/* configuração para formulário do usuário */
const Userform = (props,startValues) => {
  let { editProfile, data = {}, isFarmer } = props;
  let usernameType = startValues?.Username ? FormHelper.detectStringContent(startValues.Username) : 'email';
  let isAgricultor = !data.user?.acg_ProfileCodes__c || data.user?.acg_ProfileCodes__c?.includes('agricultor');

  switch(usernameType){
    case 'cpf':
      startValues.CPF__c = startValues.Username;
    break;
    case 'email':
      startValues.Email = startValues.Username;
    break;
  }

  // let isEmailDisabled = startValues?.Email || (editProfile && data?.user?.Email && (!data?.user?.AuthRegisterEmail || data?.user?.EmailIsReal));
  let isEmailDisabled = !editProfile && startValues?.Email;

  let form = {
    Content1: FormHelper.useFormInput({
      type: 'content',
      content: 'Os itens com * são obrigatórios',
    }),
    Username: FormHelper.useFormInput({
      id: 'username',
      label: props.config.strings.form.label.USERNAME_TEXT,
      type: usernameType === 'text' ? 'text' : 'hidden',
      inputOptions: {
        disabled: 'disabled',
        spellCheck: false,
      },
      startValue: startValues?.Username,
    }),
    CPF__c: FormHelper.useFormInput({
      id: 'cpf',
      label: props.config.strings.form.label.CPF,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_CPF,
        },
        {
          type: 'cpf',
          errorMessage: props.config.strings.form.validation.INVALID_CPF,
        },
      ],
      inputOptions: {
        title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
        disabled: editProfile || usernameType === 'cpf' ? 'disabled' : false,
        inputMode: 'numeric',
        spellCheck: false,
      },
      mask: 'username',
      maskOptions: ['cpf'],
      startValue: startValues?.CPF__c,
    }),
    FirstName: FormHelper.useFormInput({
      id: 'firstName',
      label: props.config.strings.form.label.FIRST_NAME,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_FIRST_NAME,
        },
        {
          type: 'personName',
          errorMessage: props.config.strings.form.validation.INVALID_FIRST_NAME,
        }
      ],
      size: {
        desktopQty: 6,
        phoneQty: 12,
      },
      inputOptions: {
        title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
        maxLength: 40,
      },
      startValue: startValues?.FirstName,
    }),
    LastName: FormHelper.useFormInput({
      id: 'lastName',
      label: props.config.strings.form.label.LAST_NAME,
      type: 'text',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_LAST_NAME,
        },
        {
          type: 'personName',
          errorMessage: props.config.strings.form.validation.INVALID_LAST_NAME,
        },
      ],
      size: {
        desktopQty: 6,
        phoneQty: 12,
      },
      inputOptions: {
        title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
        disabled: editProfile ? 'disabled' : false,
        spellCheck: false,
        maxLength: 60,
      },
      startValue: startValues?.LastName,
    }),
    Email: FormHelper.useFormInput({
      id: 'email',
      label: isAgricultor
        ? props.config.strings.form.label.EMAIL 
        : props.config.strings.form.label.EMAIL_REQUIRED,
      type: 'text',
      mask: 'email',
      validations: 
        isAgricultor
        ? 
          [
            {
              type: 'email',
              errorMessage: props.config.strings.form.validation.INVALID_EMAIL,
            }
          ]
          : 
          [
            {
              type: 'required',
              errorMessage: props.config.strings.form.validation.EMPTY_EMAIL,
            },
            {
              type: 'email',
              errorMessage: props.config.strings.form.validation.INVALID_EMAIL,
            }
          ],
      inputOptions: {
        // title: editProfile ? 'Para alterar esta informação, por favor, entre em contato.' : null,
        // disabled: isEmailDisabled,
        spellCheck: false,
        disabled: editProfile || isEmailDisabled ? 'disabled' : false,
      },
      startValue: startValues?.Email,
      
    }),
    LoyaltyBirthdate__c: FormHelper.useFormInput({
      id: 'birthdate',
      label: props.config.strings.form.label.BIRTH_DATE,
      type: 'text',
      mask: 'date',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_BIRTH_DATE,
        },
        {
          type: 'age',
          config: {minAge: 18, maxAge: 130},
          errorMessage: props.config.strings.form.validation.INVALID_DATE,
        },
      ],
      inputOptions: {
        spellCheck: false,
        inputMode: 'numeric',
      },
      startValue: startValues?.LoyaltyBirthdate__c,
    }),
    Password: FormHelper.useFormInput({
      id: 'password',
      hideOn: ['update'],
      label: props.config.strings.form.label.PASSWORD,
      type: 'password',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_PASSWORD,
        },
        {
          type: 'passwordTips',
          errorMessage: props.config.strings.form.validation.INVALID_PASSWORD_COMPOSITION,
        }
      ],
      contentTips: ['chars:8','uppercase:1','lowercase:1','number:1','symbol:1'],
    }),
    PasswordConfirmation: FormHelper.useFormInput({
      id: 'password-confirmation',
      hideOn: ['update'],
      label: props.config.strings.form.label.CONFIRM_PASSWORD,
      type: 'password',
      validations: [
        {
          type: 'required',
          errorMessage: props.config.strings.form.validation.EMPTY_CONFIRM_PASSWORD,
        },
        {
          type: 'custom',
          errorMessage: props.config.strings.form.validation.IDENTICAL_PASSWORD,
          customFunction: async (formInput,validation,aInputs) => {
            let isValid = true;

            aInputs.every((input) => {
              if(input.id === 'password' && input.getValue() !== formInput.getValue()){
                isValid = false;
                return false;
              }

              return true;
            })

            return isValid;
          }
        },
      ],
    }),
    // TermsConfirmation: FormHelper.useFormInput({
    //   id: 'terms-confirmation',
    //   hideOn: ['update'],
    //   label: props.config.strings.form.label.TERMS_AND_PRIVACY,
    //   type: 'checkbox',
    //   validations: [{
    //     type: 'required',
    //     errorMessage: props.config.strings.form.validation.EMPTY_TERMS_CHECKBOX,
    //   }],
    // }),
  }

  form.LoyaltyPostalCode__c.onChange = async (value) => {
    form.LoyaltyPostalCode__c.setError('')
    let result = await FormHelper.getAddressByCEP(value);

    if(result.isValid){
      // let streetInfo = [result.address.street,result.address.neighborhood];
      // streetInfo = streetInfo.filter(s => s).join(', ');
      form.LoyaltyStreet__c.setValue(result.address.street);
      form.acg_AddressDistrict__c.setValue(result.address.neighborhood);
      form.LoyaltyState__c.setValue(result.address.uf);

      setTimeout(() => {
        form.LoyaltyCity__c.setValue(result.address.city);
      },500)
    }
    else if(result.isComplete){
      // está completo e inválido
      form.LoyaltyPostalCode__c.setError('CEP inválido!')
    }
  }

  return form;
}

export default Userform;