import { useContext } from "react"
import styled from '@emotion/styled';

import { ConfigContext } from "../../context/ConfigContext"
import starcoin from "../../assets/starcoin.png"
import Image from "../Molecules/Image";
import Txt from "./Txt";
import { ScreenPropsContext } from "../../context/ScreenPropsContext";

const UserPointsTag = (props) => {
  const config = useContext(ConfigContext);
  const screenProps = useContext(ScreenPropsContext);
  const user = screenProps?.data?.user;

  return <StyledUserPointsTag config={config}>
    <Image src={starcoin} width={20} height={20} />
    <Txt typo="tag-button-lg" color={config?.colors?.BRAND.LIGHT}>{user?.TotalRedeemableAvailable__c || 0}</Txt>
  </StyledUserPointsTag>
}

export default UserPointsTag;

const StyledUserPointsTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 6px 8px;
  border-radius: 64px;
  background-color: ${props => props.config?.colors?.WHITE.W10};
`