import { createSlice } from '@reduxjs/toolkit';

const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    events: {},
  },
  reducers: {
    setEvents(state, action) {
      if(!action.payload.team){
        console.error('no team for events')
      }
      else{
        state.events[action.payload.team] = action.payload.events;
      }
    },
  },
});

export const { setEvents } = eventsSlice.actions;

export default eventsSlice.reducer;