import { useState,useEffect } from "react";
import { Helmet } from "react-helmet";
import ToolHelper from "../utils/ToolHelper";

const Seo = (props) => {
  const { configFiles,location } = props;
  const [meta, setMeta] = useState({});

  useEffect(() => {
    async function tt(){
      if(configFiles){
        let routes = configFiles.routesO;
        const importedFile = await ToolHelper.importByUserType('meta',configFiles.getConfigFile);
        let metaConfig = importedFile.default;
    
        const route = ToolHelper.getRouteByLocation(location,routes);
        const metaByRoute = ToolHelper.getMetaByRoute(route,metaConfig);
        setMeta(metaByRoute);
      }
    }

    tt();
  },[configFiles,location])

  return <Helmet>
    {meta?.title ? <title>{meta.title}</title> : null}
    {Object.entries(meta).map(([key,value]) => {
      return <meta key={key} name={key} content={value} data-react-helmet="true" />
    })}
  </Helmet>
}

export default Seo;