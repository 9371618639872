import styled from '@emotion/styled';
import { useContext } from 'react';
import { FaUser } from 'react-icons/fa6';

import Image from '../Molecules/Image';
import { ScreenPropsContext } from '../../context/ScreenPropsContext';
import { DeviceContext } from '../../context/DeviceContext';

const Avatar = (props) => {
  const { size = 48 } = props;
  const screenProps = useContext(ScreenPropsContext);
  const device = useContext(DeviceContext);
  const { config, routes, data } = screenProps;

  return (
    <StyledAvatar device={device} href={global.isAuthenticated ? routes.profile.path : routes.login.path} config={config} size={size}>
      {data?.user?.photoURL ?
        <Image src={data?.user?.photoURL} width={size} height={size} /> :
        <FaUser size={size / 2} color={config.colors.BRAND.DARK} />
      }
    </StyledAvatar>
  )
}

export default Avatar;

const StyledAvatar = styled.a`
  background-color: ${props => props.config.colors.BRAND.GOLD};
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: ${props => props.device === 'mobile' ? 
    'initial' : `0px 0px 2px 1px ${props.config.colors.BRAND.GOLD}`};

  img{
    border-radius: ${props => props.size}px;
    border: 2px solid ${props => props.config.colors.BRAND.GOLD};
  }
`;