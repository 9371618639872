import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Button from "../../components/Atoms/Button";
import Txt from "../../components/Atoms/Txt";
import { BREAKPOINTS } from "../configs/consts";
import Cookies from "js-cookie";

const PrivacyCookiesPolicy = (props) => {
  const { config } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setIsVisible(true);
    }
    if (cookieConsent === "declined") {
      clearCookies();
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", "accepted", { expires: 365 });
    setIsVisible(false);
  };

  const handleDecline = () => {
    Cookies.set("cookieConsent", "declined", { expires: 365 });
    clearCookies();
    setIsVisible(false);
  };

  const clearCookies = () => {
    setTimeout(() => {
      const allCookies = Cookies.get();
      Object.keys(allCookies).forEach((cookie) => {
        if (cookie !== "cookieConsent") {
          Cookies.remove(cookie);
        }
      });
    }, 300);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <StyledContainer config={config}>
      <section className="left-section">
        <Txt typo="subtitle-xl" align="left" className="left-section-Title">
          Preferências de Cookies
        </Txt>
        <p className="left-section-message">
          Usamos cookies para garantir que você tenha a melhor experiência no
          nosso site. Acesse nossa{" "}
          <a
            href="https://cruzeiro-website-project-documents.s3.amazonaws.com/Documentos+obrigat%C3%B3rios+SITE/POL%C3%8DTICA+DE+PRIVACIDADE/politicadeprivacidade2706_cruzeirosaf.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Política de Privacidade
          </a>{" "}
          para mais informações.
        </p>
      </section>
      <section className="right-section">
        <Button className="interact-button left-button" onClick={handleAccept}>
          <Txt typo="heading-sm" className="interact-button-text left-text">
            Aceito
          </Txt>
        </Button>
        <Button
          className="interact-button right-button"
          onClick={handleDecline}
        >
          <Txt typo="tag-button-lg" className="interact-button-text right-text">
            Não aceito
          </Txt>
        </Button>
      </section>
    </StyledContainer>
  );
};

export default PrivacyCookiesPolicy;

// styles

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 850px;
  padding: 16px;
  margin-bottom: 25px;
  border-radius: 16px;
  z-index: 99999;
  background-color: ${(props) => props.config.colors.WHITE.W};
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 16px 0px 16px;
    margin-bottom: 0px;
    border-radius: 0;
    width: 100%;
  }

  .left-section {
    padding-right: 24px;
    @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
      padding-right: 0;
    }

    .left-section-Title {
      color: ${(props) => props.config.colors.BRAND.CRUZEIRO};
    }

    .left-section-message {
      font-family: Sarabun;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;

      margin-top: 8px;
      color: ${(props) => props.config.colors.BRAND.CRUZEIRO};

      a {
        color: ${(props) => props.config.colors.BRAND.CRUZEIRO};
        font-weight: 700;
        text-decoration: underline;
      }

      @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .right-section {
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
      width: 100%;
      margin: 24px 0;
      justify-content: center;
    }

    .interact-button {
      background-color: ${(props) => props.config.colors.BRAND.GOLD};
      color: ${(props) => props.config.colors.BRAND.DARK};
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
    }

    .right-button {
      background-color: ${(props) => props.config.colors.WHITE.W};
      border: 1px solid ${(props) => props.config.colors.BRAND.DARK};
    }

    .interact-button-text {
      padding: 16px;
      white-space: nowrap;
    }
  }
`;
