import { useEffect, useState, useContext, useMemo } from "react";
import FormHelper from "../components/utils/FormHelper";
import moment from "moment";
import styled from "@emotion/styled";

/* configuração para formulário para abrir um chamado */
const Caseform = (props, startValues, startLoadCallback) => {
  let [ types, setTypes ] = useState([]);
  let [ recordTypeId, setRecordTypeId ] = useState('');
  
  let typeInput = FormHelper.useFormInput({
    id: 'type',
    label: props.config.strings.form.label.TYPE,
    type: 'select',
    validations: [{
      type: 'required',
      errorMessage: props.config.strings.form.validation.EMPTY_TYPE,
    }],
    // startValue: startValues?.Type,
    onChange: (value, input) => {
      let type = types.filter(type => {
        return type.value === value
      })

      if(type[0]?.reasons){
        reasonInput.setOptions(type[0].reasons);
      }

      startLoadCallback(value, input);
    }
  })

  let reasonInput = FormHelper.useFormInput({
    id: 'reason',
    label: props.config.strings.form.label.REASON,
    type: 'select',
    validations: [{
      type: 'required',
      errorMessage: props.config.strings.form.validation.EMPTY_REASON,
    }],
    onChange: (value, input) => {
      startLoadCallback(value, input);
    }
  })

  const CaseNewformNotLoggedIn = (props, startValues, typeInput, reasonInput) => {
    return {
      Name: FormHelper.useFormInput({
        id: 'name',
        label: props.config.strings.form.label.NAME,
        type: 'text',
        validations: [
          {
            type: 'required',
            errorMessage: props.config.strings.form.validation.EMPTY_FIRST_NAME,
          }
        ],
        inputOptions: {
          spellCheck: false,
          maxLength: 40,
        },
        startValue: startValues?.Name,
        onChange: (value, input) => {
          startLoadCallback(value, input);
        }
      }),
      Type: typeInput,
      Reason: reasonInput,
      Email: FormHelper.useFormInput({
        id: 'email',
        label: props.config.strings.form.label.EMAIL,
        type: 'text',
        startValue: startValues?.Email,
        validations: [
          {
            type: 'required',
            errorMessage: props.config.strings.form.validation.EMPTY_EMAIL,
          },
          {
            type: 'email',
            errorMessage: props.config.strings.form.validation.INVALID_EMAIL,
          },
        ],
        onChange: (value, input) => {
          startLoadCallback(value, input);
        }
      }),
      Subject: FormHelper.useFormInput({
        id: 'subject',
        label: props.config.strings.form.label.SUBJECT,
        type: 'text',
        startValue: startValues?.Subject,
        validations: [
          {
            type: 'required',
            errorMessage: props.config.strings.form.validation.EMPTY_SUBJECT,
          },
        ],
        onChange: (value, input) => {
          startLoadCallback(value, input);
        }
      }),
      CPF__c: FormHelper.useFormInput({
        id: 'cpf',
        label: props.config.strings.form.label.CPF_ONLY,
        type: 'text',
        validations: [
          {
            type: 'required',
            errorMessage: props.config.strings.form.validation.EMPTY_CPF,
          },
          {
            type: 'cpf',
            errorMessage: props.config.strings.form.validation.INVALID_CPF,
          },
        ],
        inputOptions: {
          inputMode: 'numeric',
          spellCheck: false,
        },
        mask: 'username',
        maskOptions: ['cpf'],
        startValue: startValues?.CPF__c,
        onChange: (value, input) => {
          startLoadCallback(value, input);
        }
      }),
      Message: FormHelper.useFormInput({
        id: 'message',
        label: props.config.strings.form.label.DESCRIPTION,
        type: 'textarea',
        validations: [{
            type: 'required',
            errorMessage: props.config.strings.form.validation.EMPTY_DESCRIPTION,
        }],
        inputOptions: {
          spellCheck: false,
          maxLength: 250,
        },
        startValue: startValues?.Description,
        onChange: (value, input) => {
          startLoadCallback(value, input);
        }
      }),
      Images__c: FormHelper.useFormInput({
        id: 'images',
        label: 'Anexar imagem',
        type: 'image',
        inputOptions: {
          path: 'CaseFiles',
          imageIdGenerator: () => {
            return "imagem_case-" + moment().format("YYYYMMDDhhmmss") + "-" + Math.random().toString().slice(2,11);
          },
          maxImages: 5,
          accept: ".jpg, .jpeg, .png"
        }
      })
    }
  }
  
  const CaseNewform = (props, startValues, typeInput, reasonInput) => {
    return {
      Type: typeInput,
      Reason: reasonInput,
      Subject: FormHelper.useFormInput({
        id: 'subject',
        label: props.config.strings.form.label.SUBJECT,
        type: 'text',
        startValue: startValues?.Subject,
        validations: [
          {
            type: 'required',
            errorMessage: props.config.strings.form.validation.EMPTY_SUBJECT,
          },
        ],
        onChange: (value, input) => {
          startLoadCallback(value, input);
        }
      }),
      Message: FormHelper.useFormInput({
        id: 'message',
        label: props.config.strings.form.label.DESCRIPTION,
        type: 'textarea',
        validations: [{
            type: 'required',
            errorMessage: props.config.strings.form.validation.EMPTY_DESCRIPTION,
        }],
        inputOptions: {
          spellCheck: false,
          maxLength: 250,
        },
        startValue: startValues?.Description,
        onChange: (value, input) => {
          startLoadCallback(value, input);
        }
      }),
      Images__c: FormHelper.useFormInput({
        id: 'images',
        label: 'Anexar imagem',
        type: 'image',
        inputOptions: {
          path: 'CaseFiles',
          imageIdGenerator: () => {
            return "imagem_case-" + moment().format("YYYYMMDDhhmmss") + "-" + Math.random().toString().slice(2,11);
          },
          maxImages: 5,
          accept: ".jpg, .jpeg, .png"
        }
      })
    }
  }

  useEffect(() => {
    getAppSettings();
  },[])

  const getAppSettings = async () => {
    let result = await props.dataHelper.getCaseSettings();
    typeInput.setOptions(result.types);
    setTypes(result.types);
    setRecordTypeId(result.recordType);
  }

  let inputs = null;

  if(startValues.user.UserFirebaseId === undefined) {
    inputs = CaseNewformNotLoggedIn(startValues.screenProps, startValues.user, typeInput, reasonInput);
  } else {
    inputs = CaseNewform(startValues.screenProps, startValues.user, typeInput, reasonInput);
  }

  let retorno = {
      inputs : inputs,
      recordTypeId: recordTypeId
  }

  return retorno;
}
export default Caseform;

const MessageImageBox = styled.div`
  background-color: #F6F6F6;
  padding: 10px;
  border-radius: 10px;
  width: 100%;

  span {
    color: #4d80af;
    font-weight: 700;
  }
`