import styled from "@emotion/styled";
import { useContext } from "react";
import { ScreenPropsContext } from "../../context/ScreenPropsContext";
import Button from "./Button";
import Image from "../Molecules/Image";
import logoSocioIcon from '../../assets/logo-socio-icon.png';
import { BREAKPOINTS } from "../configs/consts";
import { DEFAULT_LOYALTY_CATEGORY } from "../utils/consts";
import ToolHelper from "../utils/ToolHelper";
import { useSelector } from "react-redux";

const SocioButton = (props) => {
  const screenProps = useContext(ScreenPropsContext);
  const { config, data, routes, dataHelper, history } = screenProps;
  const { user } = data;

  const { redirectMember, newMembershipPlans } = useSelector((state) => state.membership);

  const handleGoToSocioMigration = async () => {
    if (redirectMember) {
      await dataHelper.handleGoToSocio5estrelas(user, dataHelper);
    } else {
      history.push(routes.socioSSO.path);
    }
  };

  if (!user?.LoyaltyCategory__c || user.LoyaltyCategory__c === DEFAULT_LOYALTY_CATEGORY) {
    return (
      <StyledSocioButton onClick={handleGoToSocioMigration}>
        <Button className='socio-button' config={config} color='5star'>
          <Image className='five-star-image' src={logoSocioIcon} /> Seja Sócio
        </Button>
      </StyledSocioButton>
    )
  }

  if (!newMembershipPlans.some((plans) => plans?.name?.includes(user?.LoyaltyCategory__c))) {
    return (
      <StyledSocioButton onClick={handleGoToSocioMigration}>
        <Button className='socio-button' config={config} color='5star'>
          <Image className='five-star-image' src={logoSocioIcon} /> Migrar Plano
        </Button>
      </StyledSocioButton>
    )
  }

  return <div />
}

export default SocioButton;

const StyledSocioButton = styled.a`
  .socio-button{
    .five-star-image{
      height: 26px;

      @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
        height: 18px;
      }
    }
  }
`