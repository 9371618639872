import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { useWindowSize } from "../utils/useWindowSize";
import { BREAKPOINTS } from "../configs/consts";

const CoreLink = props => {
  let { to, url, target, children, exact, className, routeO, label, menu, toggleMenu } = props;

  const closeMenu = () => {
    toggleMenu();
  }

  // Alias
  if(url && !to){
    to = {pathname: url};
  }

  // Alias
  if(label && !children){
    children = label;
  }

  if(routeO){
    // Se foi definido permissão
    if(
      (routeO.allowedTypes && (!global.userTypes || !routeO.allowedTypes.filter(value => global.userTypes.includes(value)).length)) ||
      (routeO.notAllowedTypes && (!global.userTypes || routeO.notAllowedTypes.filter(value => global.userTypes.includes(value)).length))
    ){
      return null;
    }
    
    to = routeO.path || '';
  }

  if(!to || (menu !== undefined && menu.items.length > 0)) {
    return <StyledHeaderDropMenuItem>{children}</StyledHeaderDropMenuItem>
  }

  return <NavLink onClick={closeMenu} exact={exact} to={to} target={target} className={className}>{children}</NavLink>
}

export default CoreLink;

const StyledHeaderDropMenuItem = styled.span`
  color: rgba(255, 255, 255, 1.0);
  display: flex;
  justify-content: space-between;
  padding: 0.7em 0;
  font-size: 1.3em;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.config?.themeLayout?.title};
  cursor: initial;
`