import { HEROKU_AUTHORIZATION, HEROKU_CRUZEIRO_CPF_CHECK, HEROKU_CRUZEIRO_SOCIO_SSO_EXTERNAL, HEROKU_CRUZEIRO_SOCIO_SSO_REGISTER, HEROKU_CRUZEIRO_TICKET_CHECK, HEROKU_CRUZEIRO_FENG_BASIC_SSO_LOGIN } from "./URLconsts";

const CruzeiroApi = {
  socioSSO: async function (params) {
    const { user, plan, dataHelper, redirect_uri } = params;
    var result = {
      success: false,
      code: 0,
      data: [],
    };

    const postData = {
      userId: user.FirebaseId__c,
      plan,
      redirect_uri,
    };

    console.log("postData", postData);

    const postUrl = await dataHelper.getHerokuUrl(
      HEROKU_CRUZEIRO_SOCIO_SSO_EXTERNAL
    );

    await fetch(postUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: HEROKU_AUTHORIZATION,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.error?.code) {
          result = {
            success: false,
            code: data.error.code,
            message: data.error.message,
          };
        } else if (data?.res) {
          result.success = true;
          result.url = data.res;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return result;
  },
  socioRegister: async function (userData, dataHelper) {
    var result = {
      success: false,
      code: 0,
      data: [],
    };

    const postData = { userId: userData.FirebaseId__c };

    const postUrl = await dataHelper.getHerokuUrl(
      HEROKU_CRUZEIRO_SOCIO_SSO_REGISTER
    );

    await fetch(postUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: HEROKU_AUTHORIZATION,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.error?.code) {
          result = {
            success: false,
            code: data.error.code,
            message: data.error.message,
          };
        } else if (data?.res) {
          result.success = true;
          result.fengId = data.res.user.idpessoa;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return result;
  },
  CPFCheck: async function (userData, dataHelper) {
    let data = {
      userId: userData.FirebaseId__c,
    };

    var cpfCheckUrl = await dataHelper.getHerokuUrl(HEROKU_CRUZEIRO_CPF_CHECK);

    try {
      let response = await fetch(
        cpfCheckUrl + "?" + new URLSearchParams(data),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: HEROKU_AUTHORIZATION,
          },
        }
      );

      if (response.ok) {
        let result = await response.json();
        return result;
      }
    } catch (e) {
      console.log("e", e);
    }
  },
  loginTicketSSO: async function (cpf, email, dataHelper) {
    try {
      const data = {
        cpf,
        email,
        firstName: '',
        lastName: '',
        birthDate: '',
      };

      var ticketCheckUrl = await dataHelper.getHerokuUrl(HEROKU_CRUZEIRO_TICKET_CHECK);

      let response;

      await fetch(ticketCheckUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          const { success, login_url } = data || {};

          if (success) {
            response = {
              success,
              login_url,
            };
          }
        })
        .catch((error) => {
          console.error(error);
        });

      if (response) return response;
    } catch (error) {}

    return {
      success: false,
    };
  },
  socioSSOFeng: async function (userData, dataHelper) {
    var result = {
      success: false,
      code: 0,
      data: [],
    };

    const postData = { cpf: '12520749644', idpessoa: userData.IdFeng__c || userData.FengId__c };

    const postUrl = await dataHelper.getHerokuUrl(HEROKU_CRUZEIRO_FENG_BASIC_SSO_LOGIN);
  
    await fetch(postUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: HEROKU_AUTHORIZATION,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.error?.code) {
          result = {
            success: false,
            code: data.error.code,
            message: data.error.message,
          };
        } else if (data?.res) {
          result.success = true;
          result.url = data.res;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return result;
  },
}

export default CruzeiroApi;
