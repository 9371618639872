import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App.js';
import './assets/fonts/fonts.css';
import './assets/css/font-awesome.min.css';

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
}

const container = document.getElementById('root');
const root = createRoot(container)

const routing = (
  <BrowserRouter>
    <App/>
  </BrowserRouter>
)
 
root.render(routing);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
