import { createSlice } from '@reduxjs/toolkit';

const socialMediasSlice = createSlice({
  name: 'socialMedias',
  initialState: {
    socialMedias: {},
  },
  reducers: {
    setSocialMedias(state, action) {
      state.socialMedias = action.payload.socialMedias;
    },
  },
});

export const { setSocialMedias } = socialMediasSlice.actions;

export default socialMediasSlice.reducer;