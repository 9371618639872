const firebaseConfig = {
  apiKey: "AIzaSyACJhc59EYtNYH1UchOUNdcjLwPHazWKPw",
  authDomain: "cruzeiro-e553e.firebaseapp.com",
  projectId: "cruzeiro-e553e",
  storageBucket: "cruzeiro-e553e.appspot.com",
  messagingSenderId: "612402967643",
  appId: "1:612402967643:web:5f230e16e0807349a6bb7e",
  measurementId: "G-EF75QCTCTV"
};


export default firebaseConfig;