import { createSlice } from "@reduxjs/toolkit";

const eventsSlice = createSlice({
  name: "audienceTypes",
  initialState: {
    audienceTypes: {},
  },
  reducers: {
    setAudienceTypes(state, action) {
      state.audienceTypes = action.payload;
    },
  },
});

export const { setAudienceTypes } = eventsSlice.actions;

export default eventsSlice.reducer;
