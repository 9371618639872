import styled from "@emotion/styled";
import { useContext, useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import Txt from "../../components/Atoms/Txt";
import Link from "../../components/Atoms/Link";
import { FaArrowRight } from "react-icons/fa6";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { ScreenPropsContext } from "../../context/ScreenPropsContext";
import Footer from "../../components/Footer";
import { DeviceContext } from "../../context/DeviceContext";
import { BREAKPOINTS } from "../../components/configs/consts";

function NoMatchBase(props) {
  const screenProps = useContext(ScreenPropsContext);
  const { config, routes } = screenProps;
  const device = useContext(DeviceContext);
  const iconSize = useMemo(() => device === 'desktop' ? 120 : 90,[device])

  useEffect(() => {
    props.setShowHeader(true);
  }, []);

  return (
    <>
      <StyledNoMatch config={config}>
        <div className="info-container">
          <div className="info-icon">
            <AiOutlineExclamationCircle size={iconSize} color={config.colors.BRAND.CRUZEIRO} />
          </div>
          <div className="info-description">
            <Txt typo="heading-xl">Erro 404</Txt>
            <Txt typo="paragraph-xl">Página não encontrada. Continue navegando pelo nosso site.</Txt>
            <Link href={routes.home.path} typo="subtitle-lg" color={config.colors.BRAND.BASE}>Voltar para a página inicial <FaArrowRight /></Link>
          </div>
        </div>
      </StyledNoMatch>
      <Footer {...props} />
    </>
  );
}

const NoMatchPage = withRouter(NoMatchBase);

export default NoMatchPage;

const StyledNoMatch = styled.div`
  background-color: ${props => props.config.colors.WHITE.W};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;

  .info-container{
    max-width: 940px;
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media (max-width:${BREAKPOINTS.PHONE_BIGGER}px) {
      flex-direction: column;
    }

    .info-icon{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info-description{
      color: ${props => props.config.colors.BRAND.CRUZEIRO};
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;

      @media (max-width:${BREAKPOINTS.PHONE_BIGGER}px) {
        align-items: center;

        span{
          text-align: center;
        }
      }
    }
  }

`