import styled from '@emotion/styled';

/*
  props
    color
    size
*/

function HamIcon(props){
  let { size, color, className } = props;
  size = size || 45;

  return <StyledHamIcon size={size} className={className}>
    <div className={'icon-container'}>
      <HamLine size={size} color={color}/>
      <HamLine size={size} color={color}/>
      <HamLine size={size} color={color}/>
    </div>
  </StyledHamIcon>
}

export default HamIcon;

/* style */

const HamLine = styled.span(props => ({
  display: 'block',
  position: 'absolute',
  height: props.size * 0.1,
  width: '100%',
  background: props.color || '#333',
  opacity: '1',
  left: '0',
  transform: 'rotate(0deg)',
  transition: '.25s ease-in-out',
  '&:nth-of-type(1)':{
    top: 0,
    transformOrigin:'left center',
  },
  '&:nth-of-type(2)':{
    top: props.size * 0.35,
    transformOrigin:'left center',
  },
  '&:nth-of-type(3)':{
    top: props.size * 0.7,
    transformOrigin:'left center',
  },
}));

const StyledHamIcon = styled.div`
  width: ${props => props.size}px;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  .icon-container{
    position: relative;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
  }
  
  &.open {
    span:nth-of-type(1){
      transform: rotate(45deg);
      top: ${props => 0.066 * props.size}px;
      left: ${props => 0.15 * props.size}px;
    }
    span:nth-of-type(2){
      width: 0%;
      opacity: 0;
    }
    span:nth-of-type(3){
      transform: rotate(-45deg);
      top: ${props => props.size * 0.766}px;
      left: ${props => props.size * 0.15}px;
    }
  }
`
