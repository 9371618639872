// CRU component

import { useContext } from "react";
import styled from "@emotion/styled";

import { ConfigContext } from "../../context/ConfigContext";
import { DeviceContext } from "../../context/DeviceContext";
import { TypographyContext } from "../../context/TypographyContext";
import { TextColorContext } from "../../context/TextColorContext";

const Txt = (props) => {
  const { className, align, typoMobile, dangerouslySetInnerHTML, children, translate = true } = props;
  const contextConfig = useContext(ConfigContext);
  const contextDevice = useContext(DeviceContext); // desktop ou mobile
  const typography = useContext(TypographyContext);
  const textColor = useContext(TextColorContext);

  const config = props.config || contextConfig;
  const device = contextDevice || 'desktop';
  const color = props.color || textColor;
  let typo = props.typo || typography;

  if(device === 'mobile' && typoMobile){
    typo = typoMobile;
  }

  return <StyledTxt 
    config={config} 
    className={className + ' ' + typo + ' ' + device}
    style={{color: color}}
    align={align}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    translate={translate ? 'yes' : 'no'}
  >
    {children}
  </StyledTxt>
}

export default Txt;

const StyledTxt = styled.span`
  font-size: 1em;
  color: ${props => props.config?.themeLayout?.text};
  display: flex;
  align-items: ${props => props.align || 'center'};
  justify-content: ${props => props.align || 'center'};
  gap: 0.5em;
  letter-spacing: .04em;
  font-variant-numeric: tabular-nums;


  &.heading-footer-lg{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    &.mobile{
      font-size: 18px;
      line-height: 22.5px;
      font-weight: 700;
    }
  }

  &.heading-xxxl-phudu {
    font-family: "Phudu";
    text-transform: uppercase;
    font-weight: bold;
    font-size: 155px;
    line-height: 155px;
    letter-spacing: .04em;

    &.mobile{
      font-size: 65px;
      line-height: 72px;
    }
  }

  &.heading-xxl-phudu {
    font-family: "Phudu";
    text-transform: uppercase;
    font-weight: bold;
    font-size: 52px;
    line-height: 57px;
    letter-spacing: .04em;

    &.mobile{
      font-size: 41px;
      line-height: 45px;
    }
  }

  &.heading-xl-phudu {
    font-family: "Phudu";
    text-transform: uppercase;
    font-weight: bold;
    font-size: 82px;
    line-height: 90px;
    letter-spacing: .04em;

    &.mobile{
      font-size: 32px;
      line-height: 35px;
    }
  }

  &.heading-xl{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 38px;
    line-height: 49.4px;
    letter-spacing: .04em;

    &.mobile{
      font-size: 24px;
      line-height: 31.2px;
    }
  }

  &.heading-lg-phudu{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 41px;
    line-height: 39px;

    &.mobile{
      font-size: 24px;
      line-height: 19px;
      font-weight: 700;
    }
  }

  &.heading-lg{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 32px;

    &.mobile{
      font-size: 18px;
      line-height: 22.5px;
      font-weight: 700;
    }
  }

  &.heading-md-figtree{
    font-family: Figtree;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;

    &.mobile{
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.heading-md-phudu {
    font-family: Phudu;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;

    &.mobile{
      font-size: 16px;
      line-height: 17px;
    }
  }

  &.heading-md{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 28px;

    &.mobile{
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.heading-sm{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 21px;
  }

  &.subtitle-xl{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 21px;
  }

  &.subtitle-lg-figtree{
    font-family: Figtree;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: .04em;

    &.mobile{
      font-size: 12px;
      line-height: 14px;
    }
  }

  &.subtitle-lg{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: .04em;

    &.mobile{
      font-size: 12px;
      line-height: 15px;
    }
  }

  &.subtitle-md{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;

    &.mobile{
      font-size: 12px;
      line-height: 15px;
    }
  }

  &.subtitle-sm{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;

    &.mobile{
      font-size: 10px;
      line-height: 12.5px;
    }
  }

  &.tag-button-xl{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;

    &.mobile{
      font-size: 16px;
      line-height: 19px;
    }
  }

  &.tag-button-lg{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;

    &.mobile{
      font-size: 14px;
      line-height: 17.5px;
    }
  }

  &.tag-button-md{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;

    &.mobile{
      font-size: 10px;
      line-height: 14px;
    }
  }

  &.tag-button-sm{
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    &.mobile{
      font-size: 10px;
      line-height: 12px;
    }
  }

  &.paragraph-xl{
    font-family: Sarabun;
    font-weight: 400;
    font-size: 21px;
    line-height: 33.6px;

    &.mobile{
      font-size: 16px;
      line-height: 25.6px;
    }
  }

  &.paragraph-lg{
    font-family: Sarabun;
    font-weight: 400;
    font-size: 18px;

    &.mobile{
      font-size: 14px;
      line-height: 18.2px;
    }
  }

  &.paragraph-md{
    font-family: Sarabun;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    &.mobile{
      font-size: 12px;
      line-height: 18px;
    }
  }

  &.paragraph-sm{
    font-family: Sarabun;
    font-weight: 400;
    font-size: 14px;

    &.mobile{
      font-size: 10px;
      line-height: 13px;
      font-weight: 500;
    }
  }

  &.caption-lg-footer{
    font-family: Sarabun;
    font-weight: 500;
    font-size: 14px;

    &.mobile{
      font-size: 12px;
      line-height: 15.6px;
      text-align: center;
    }
  }

  &.caption-lg{
    font-family: Sarabun;
    font-weight: 500;
    font-style: italic;
    font-size: 14px;

    &.mobile{
      font-size: 12px;
      line-height: 15.6px;
      text-align: center;
    }
  }

  &.caption-md{
    font-family: Sarabun;
    font-weight: 500;
    font-size: 12px;
  }

  &.caption-sm{
    font-family: Sarabun;
    font-weight: 500;
    font-size: 10px;
  }
`