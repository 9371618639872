import styled from '@emotion/styled'
import Txt from './Txt';
import { useContext } from 'react';
import { DeviceContext } from '../../context/DeviceContext';

// VARIAÇÕES

// 1) Largura (width)
// - Largura do conteúdo  : content [default]
// - Largura do container : container
// - Largura pré-definido : custom (definir widthValue)

// 2) Cor (color)
// - Cor do "5 estrelas" : 5star (aquele degradê)
// - Padrão BRAND.DARK   : default

// 3) Tamanho (size) - Define o padding
// - Pequeno : small (8px 11px)
// - Médio   : medium (8px 16px) [default]
// - Grande  : large (11px 32px)

const Button = (props) => {
  let { isLoading, display, backgroundColor, textColor, color, textAlign, disabled, width, heightValue = '48px', widthValue, typo, config, size } = props;
  let isDisabled = props.className?.includes('disabled');
  const buttonType = props.href?.length || props.onClick ? 'a' : 'div';
  const device = useContext(DeviceContext);

  let flex;
  switch(width){
    case 'large':
      widthValue = '328px';
    break;
    case 'container':
      flex = 1;
    break;
    default:
      if(device === 'mobile'){
        flex = 1;
      }
      else{
        flex = 'initial';
      }
  }

  let borderRadius = config?.layout?.BUTTON_RADIUS;
  switch(device){
    case 'mobile':
      borderRadius = '4px';
      heightValue = '32px';
      typo = 'heading-lg'
    default:
      typo = 'subtitle-lg'
  }

  let padding;
  switch(size){
    case 'small':
      padding = '8px 11px';
    case 'large':
      padding = '11px 32px';
    case 'medium': default:
      if(device === 'mobile'){
        padding = '4px 8px';
      }
      else{
        padding = '8px 16px';
      }
  }

  let backgroundImage;
  switch(color){
    case '5star':
      backgroundImage = `linear-gradient(to right,${config?.colors?.BRAND.BASE} 0%, ${config?.colors?.BRAND.LIGHT} 100%)`;
    break;
    default:
      backgroundImage = 'initial';
  }

  const StyledButton = styled[buttonType]`
    flex: ${flex};
    width: ${widthValue};
    height: ${heightValue};
    display: ${display || 'flex'};
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: ${disabled == 1 ? config?.colors?.BRAND.DISABLED : config?.colors?.BRAND.GOLD};
    background-image: ${backgroundImage};

    color: ${disabled ? config?.themeLayout?.buttonInactiveText : textColor || (color === '5star' ? config?.colors.WHITE.W : config?.colors.BRAND.DARK)};
    cursor: pointer;
    user-select: none;
    padding: ${padding};
    border-radius: ${borderRadius};
    text-align: ${textAlign || 'center'};
    letter-spacing: .08em;

    &.secondary{
      background: ${config?.themeLayout?.buttonSecondaryBackground};
      color: ${config?.themeLayout?.buttonSecondaryText};
    }

    &.disabled{
      background: ${config?.themeLayout?.buttonInactiveBackground};
      color: ${config?.themeLayout?.buttonInactiveText};
    }


    .loading{
      margin-left: 0.5em;
    }

    .loading-bar{
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 100%;
      background: repeating-linear-gradient(to right, ${config?.themeLayout?.title} 0%, ${config?.themeLayout?.link} 50%, ${config?.themeLayout?.title} 100%);
      background-size: 200% auto;
      background-position: 0 100%;
      animation: gradient 1s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: linear;

      @keyframes gradient { 
        0%   { background-position: 0 0; }
        100% { background-position: -200% 0; }
      }
    }
  `;

  return <StyledButton {...props}>
    <Txt typo={typo}>{props.children}</Txt>
    {isLoading ? <span className={'loading-bar'} /> : ''}
  </StyledButton>
}

/* style */



export default Button;