import LoadingIcon from '../Atoms/LoadingIcon';
import { GUTTER } from '../configs/consts'
import styled from '@emotion/styled'

const Popup = (props) => {

  const closePopup = () => {
    props.control.setIsOpen(false);
  }

  if(props.control.isOpen) {
    document.body.style.overflow = "hidden"
  }
  else{
    document.body.style.overflowY = "initial"
    document.body.style.overflowX = "hidden"
  }

  return <StyledPopup {...props} gutter={GUTTER}>
    <div className={'outside'} onClick={closePopup} />
    <div className={'window'}>
      {props.control.isLoading ? 
        <div className={'loading'}>
          <LoadingIcon config={props.config}/>
        </div>
        :
        null
      }
      <div className={'close-button'} onClick={closePopup}>
        &times;
      </div>
      <div className={'content-area'}>
        {props.control.content}
      </div>
    </div>
  </StyledPopup>
}



const StyledPopup = styled.div`
  display: ${props => props.control.isOpen ? 'flex' : 'none'};
  position: fixed;
  background: rgba(0,0,0,0.64);
  backdrop-filter: blur(2px);
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  z-index:99990;
  align-items:center;
  align-content:center;

  .outside {
    position: fixed;
    top: 0; 
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }

  .window{
    position: relative;
    overflow: hidden;
    background: white;
    margin: auto;
    border-radius: 16px;
    z-index:2;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    .loading{
      position: absolute;
      background-color: white;
      width: 100%;
      height: 100%;
      margin-top: 10px;
      display: flex;
      opacity: 0.8;
    }

    .close-button{
      position: absolute;
      top: 10px;
      right: 10px;
      width: 1em;
      height: 1em;
      text-align: center;
      line-height: 0.9em;
      border-radius: 1em;
      font-size: 30px;
      color: ${props => props.control?.themeLayout?.link || '#002A86'};
      cursor: pointer;
      user-select: none;
    }

    .content-area{
      max-width: ${props => `calc(100vw - ${props.gutter * 2}px)`};
      max-height: ${props => `calc(100vh - ${props.gutter * 2}px)`};
      overflow: auto;
    }
  }
`

export default Popup;