
const COLORS = {
  BRAND: {
    CRUZEIRO: '#002A86',
    BASE: '#0055E5',
    DARK: '#000354',
    LIGHT: '#00ABFA',
    PRIMARY400: '#055EED',
    PRIMARY600: '#010160',
    GOLD: '#F3AE2A',
    DISABLED: '#bfbfbf',

    DARK20: 'rgba(0,3,84,.20)',
    DARK64: 'rgba(0,3,84,.64)',
    DARK80: 'rgba(0,3,84,.80)',
    DARK90: 'rgba(0,3,84,.90)',

    ERROR: '#FF0F00',
    WARNING: '#FFAA46',
    SUCCESS: '#9CDE59',
  },
  WHITE: {
    W04: 'rgba(255,255,255,.04)',
    W08: 'rgba(255,255,255,.08)',
    W10: 'rgba(255,255,255,.10)',
    W16: 'rgba(255,255,255,.16)',
    W32: 'rgba(255,255,255,.32)',
    W48: 'rgba(255,255,255,.48)',
    W64: 'rgba(255,255,255,.64)',
    W80: 'rgba(255,255,255,.80)',
    W96: 'rgba(255,255,255,.96)',
    W:   'rgba(255,255,255,1.0)',
  },
  BLACK: {
    B04: 'rgba(0,0,0,.04)',
    B08: 'rgba(0,0,0,.08)',
    B10: 'rgba(0,0,0,.10)',
    B16: 'rgba(0,0,0,.16)',
    B32: 'rgba(0,0,0,.32)',
    B48: 'rgba(0,0,0,.48)',
    B64: 'rgba(0,0,0,.64)',
    B80: 'rgba(0,0,0,.80)',
    B96: 'rgba(0,0,0,.96)',
    B:   'rgba(0,0,0,1.0)',
  },
  TRANSPARENT: {
    T: 'transparent',
  },
  NEUTRAL: {
    N10: '#E6E6E6',
  },
  FEEDBACK: {
    GREEN: '#09860E',
    RED: '#FF0F00',
  },

  LIGHT: {
    A: '#FFF',
    B: '#EEE',
    C: '#DDD',
    D: '#CCC',
    BG: {
      A: '#FFF',
      B: '#FD0',
      C: '#EEE',
      D: 'url("https://setecnet.com.br/home/wp-content/uploads/2018/02/betfair-pattern.png") bottom #FFF',
      E: '#f3f7e0',
    }
  },
  DARK: {
    A: '#21417C',
    B: '#8BA412',
    C: '#4d80af',
    D: '#77b9a3',
    E: '#888',
    F: '#ABC432',
    G: '#444',
    SUCCESS: '#54BF33',
    WARNING: '#FAB416',
    ERROR: '#E63B3B',
    INFO: '#17A2B8',
    BG: {
      A: '#555',
      B: '#ABC432',
      C: '#777',
      D: '#1a4299',
      E: '#000',
      F: 'rgba(0,0,0,0.8)',
      G: '#122444',
      H: '#21417C',
      I: '#5e6732',
    }
  },
  MISC: {
    TRANSPARENT: 'transparent',
    IMAGE_BG: 'url("data:image/webp;base64,UklGRmwAAABXRUJQVlA4WAoAAAAQAAAABAAABAAAQUxQSBYAAAABDzAhERFCadtIbAwea/+oRfQ/GvwBVlA4IDAAAACQAQCdASoFAAUAAgA0JaQAApplHeAA/tPpPS2F/PjfDX7Mr9vZ2Xy98v/pAAAAAAA=")',
  }
}

export default COLORS;