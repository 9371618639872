// CRU component

import styled from '@emotion/styled';
import Txt from "./Txt";
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

const Link = props => {
  const { children, typo, color, ...rest } = props;

  if(props.to){
    return <RouterLink to={props.to}>
      {React.isValidElement(children) ?
        children :
        <Txt typo={typo} color={color}>{children}</Txt>
      }
    </RouterLink>
  }

  return <StyledLink {...rest}>
    {React.isValidElement(children) ?
      children :
      <Txt typo={typo} color={color}>{children}</Txt>
    }
  </StyledLink>
}

export default Link;

/* style */

const StyledLink = styled.a`
  cursor: pointer;
`