import styled from '@emotion/styled'
import ToolHelper from '../utils/ToolHelper';
import { useEffect, useState, useMemo } from 'react'
import { BREAKPOINTS } from '../configs/consts';

const FloatingMessageBox = (props) => {
  const [extraClasses,setExtraClasses] = useState('');
  const { config, data, control, index } = props;
  const [timeoutClose,setTimeoutClose] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setExtraClasses('active');
    },100)
  },[])

  useEffect(() => {
    if(timeoutClose){
      clearTimeout(timeoutClose);
    }

    if(data.timeout !== 0){
      setTimeoutClose(setTimeout(() => {
        control.close(data.id)
      },data?.timeout || 7000))
    }
  },[data.timeout])

  const icon = useMemo(() => {
    let icon = null;

    if(data.type){
      switch(data.type){
        case 'success':
          icon = 'fa-check'
        break;
        case 'error':
          icon = 'fa-times'
        break;
        case 'warning':
          icon = 'fa-warning'
        break;
        case 'info':
          icon = 'fa-info'
        break;
        case 'loading':
          icon = 'fa-spin fa-cog'
        break;
      }
    }

    return icon;
  },[data.type])

  const closeMessage = (e,preventDefault = true) => {
    if(preventDefault)
      e.preventDefault();

    if(data.type !== 'loading')
      control.close(data.id);
  }

  return <StyledFloatingMessageBox config={config} className={extraClasses + ' ' + data.type + (data.link ? ' has-link' : '') + (data.shake ? ' shake' : '') + (data.glow ? ' glow' : '')} index={index} href={data.link} target={'_blank'} onClick={(e) => {closeMessage(e,false)}}>
    <div className={'glow-div'}/>
    {data.type !== 'loading' && <div className={'close-button'} onClick={closeMessage}>
      &times;
    </div>}
    {icon && 
      <div className={'icon-holder'}>
        <i className={'fa '+icon} />
      </div>
    }
    <div className={'text-holder'}>
      {data?.title && <p className={'title'} dangerouslySetInnerHTML={{__html: data.title }} />}
      {data?.body && <p className={'message'} dangerouslySetInnerHTML={{__html: data.body }}/>}
    </div>
  </StyledFloatingMessageBox>
}

const FloatingMessage = (props) => {
  const config = useMemo(() => ToolHelper.getThemedConfig(props,'FloatingMessage.preset'),[props.config]);

  return <StyledFloatingMessage config={config}>
    <div className={'message-holder'}>
      {props.control?.list?.map((message,index) => 
        <FloatingMessageBox key={message.id} index={index} data={message} config={config} control={props.control}/>
      )}
    </div>
  </StyledFloatingMessage>
}

/* styles */

const StyledFloatingMessage = styled.div`
  position: fixed;
  top: 50px;
  left: 50%;
  margin-left: -13em;
  z-index: 9999;
  width: 26em;
  max-width: 95vw;
  transition: all 1s;

  @media (max-width:${BREAKPOINTS.PHONE}px) {
    width: calc(100% - 30px);
    left: 15px;
    margin-left: 0;
  }
`

const StyledFloatingMessageBox = styled.a`
  display: block;
  position: relative;
  top: -100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
  transition: all 0.5s;
  cursor: pointer;
  border-radius: 4px;
  opacity: 0;
  background: ${props => props.config?.themeLayout.background};
  color: ${props => props.config?.themeLayout.text};
  user-select: none;
  margin-top: 15px;
  overflow: hidden;

  &.active{
    top: 0;
    /* top: ${props => ((props.index || 0) + 1) * 15}px; */
    opacity: 0.95;
  }

  &.success{
    background: ${props => props.config?.colors?.DARK?.SUCCESS};
    color: white;
  }

  &.error{
    background: ${props => props.config?.colors?.DARK?.ERROR};
    color: white;
  }

  &.warning{
    background: ${props => props.config?.colors?.DARK?.WARNING};
    color: white;
  }

  &.info{
    background: ${props => props.config?.colors?.DARK?.INFO};
    color: white;
  }

  &.loading{
    background: ${props => props.config?.colors?.DARK?.WARNING};
    color: white;
  }

  .close-button{
    position: absolute;
    top: 0px;
    right: 0px;
    width: 1em;
    height: 1em;
    text-align: center;
    line-height: 1em;
    border-radius: 1em;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    opacity: 0.5;

    &:hover{
      opacity: 1;
    }
  }

  &:not(.has-link):hover .close-button{
    opacity: 1;
  }

  .icon-holder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: rgba(255,255,255,0.1);

    .icon{
      flex-grow: 0;
      display: inline-block;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      max-width: 60px;
      max-height: 60px;
    }
  }

  .text-holder{
    padding: 12px;
    overflow: hidden;
    word-break: break-word;
    font-family: Sarabun;
    
    .title{
      padding-right: 10px;
      font-size: 1.1em;
      font-weight: bold;
      overflow: hidden;
    }

    .message{
      font-size: 1em;
    }
  }

  &.glow{
    .glow-div{
      animation: glow 0.5s cubic-bezier(.36,.07,.19,.97) normal;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 400px;
      height: 400px;
      background: rgba(255,255,255,0.5);
      transform: translate(-50%,-50%) scale(1);
      margin-top: 8px;
      z-index: 1;
      border-radius: 100%;
      opacity: 0;
      box-shadow: 0px 0px 5px 4px rgb(255 255 255 / 50%);
  
      @keyframes glow {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }

  &.shake{
    animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

    @keyframes shake {
      10%, 90% {
        transform: translate3d(-1px, 0, 0);
      }
      
      20%, 80% {
        transform: translate3d(2px, 0, 0);
      }

      30%, 50%, 70% {
        transform: translate3d(-3px, 0, 0);
      }

      40%, 60% {
        transform: translate3d(3px, 0, 0);
      }
    }
  }
`

export default FloatingMessage;