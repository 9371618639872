import {
  isSameWeek,
  parse,
  isSameMonth,
  isSameDay,
  endOfWeek,
  endOfMonth,
  endOfDay,
} from "date-fns";

const genericOperation = (expectedValue, currentValue, operator) => {
  switch (operator) {
    case "<>":
      return currentValue !== expectedValue;
    case "=":
      return currentValue === expectedValue;
    case "IN":
      return expectedValue.includes(currentValue);
    case ">":
      return currentValue > expectedValue;
    case ">=":
      return currentValue >= expectedValue;
    case "<":
      return currentValue < expectedValue;
    case "<=":
      return currentValue <= expectedValue;
  }
};
const isValidPeriod = (audience, userBirthdate, period) => {
  if (!userBirthdate) return false;
  const birthdate = parse(userBirthdate, "yyyy-MM-dd", new Date());
  birthdate.setFullYear(new Date().getFullYear());
  if (period === "Week" && isSameWeek(new Date(), birthdate)) {
    audience.EndDate = endOfWeek(birthdate);
  } else if (period === "Month" && isSameMonth(new Date(), birthdate)) {
    audience.EndDate = endOfMonth(birthdate);
  } else if (isSameDay(new Date(), birthdate)) {
    audience.EndDate = endOfDay(birthdate);
  }
  return audience.EndDate ? true : false;
};
const handleAudienceType = (audienceType, userData, userCampaign) => {
  let trueCounter = false;
  if (userData && audienceType.AudienceTypeCriterias__r?.records) {
    audienceType.AudienceTypeCriterias__r?.records?.forEach((criteria) => {
      trueCounter +=
        (criteria.Type__c === "Field" &&
          genericOperation(
            criteria.Value__c,
            userData[criteria.Field__c],
            criteria.Comparator__c
          )) ||
        // (criteria.Type__c === "Campaign" &&
        //   criteria.Campaign__c in userCampaign)
        (criteria.Type__c === "Birthdate" &&
          isValidPeriod(
            audienceType,
            userData.LoyaltyBirthdate__c,
            criteria.DisplayPeriod__c
          )) ||
        (criteria.Type__c === "Onboarding" && userData.isSignUp);
    });
  }

  return {
    Id: audienceType.Id,
    display:
      (audienceType.CriteriaType__c === "AND" &&
        trueCounter ===
          audienceType.AudienceTypeCriterias__r?.records.length) ||
      (audienceType.CriteriaType__c === "OR" && trueCounter)
        ? true
        : false,
    periodEndDate: audienceType.EndDate,
  };
};
const genericHelper = {
  getAudienceTypes: async (newAudienceTypes, userData, userCampaign) => {
    const jobs = [];
    const temp = {};
    newAudienceTypes.forEach((audienceType) =>
      jobs.push(
        new Promise((resolve, reject) =>
          resolve(handleAudienceType(audienceType, userData, userCampaign))
        )
      )
    );
    await Promise.all(jobs)
      .then((result) => {
        result.forEach((item) => (temp[item.Id] = item));
      })
      .catch((error) => {
        console.log("ERROR: genericHelper.getAudienceTypes", error);
      });
    return temp;
  },

  verifyCanShowContentList(snapshot, userAudience) {
    const list = [];
    snapshot?.forEach((docData) => {
      let showContent;
      const targetGroup = docData?.TargetGroup__c;

      if (!targetGroup) {
        showContent = true;
      } else {
        const isDisplayAllowed = userAudience[targetGroup]?.display;

        showContent = !!isDisplayAllowed;
      }

      docData.showContent = showContent;

      list.push(docData);
    });
    return list;
  },

  verifyCanShowContent(content, userAudience) {
    const targetGroup = content?.TargetGroup__c;

    if (!targetGroup) {
      return true;
    }

    const isDisplayAllowed = userAudience[targetGroup]?.display;

    return !!isDisplayAllowed;
  },
};
export default genericHelper;
