import styled from '@emotion/styled'
import { useContext, useEffect, useMemo, useState } from 'react';
import { ConfigContext } from '../../context/ConfigContext';
import Avatar from '../Atoms/Avatar';
import Txt from '../Atoms/Txt';
import { useSelector } from "react-redux";
import UserPointsTag from '../Atoms/UserPointsTag';
import ticket from '../../assets/ticket.png';
import bag from '../../assets/bag.png';
import Image from './Image';
import { ScreenPropsContext } from '../../context/ScreenPropsContext';
import { BREAKPOINTS } from '../configs/consts';
import SocioButton from '../Atoms/SocioButton';
import { DEFAULT_LOYALTY_CATEGORY } from '../utils/consts';


const FloatingBottomBar = (props) => {
  const limitLoyaltyItems = 2;
  const [ displayBar, setDisplayBar ] = useState('none');
  const config = useContext(ConfigContext);
  const scrollThreshold = 300;
  const screenProps = useContext(ScreenPropsContext);
  const { userHelper, data } = screenProps;
  const user = data?.user;
  const [loyaltyItems, setLoyaltyItems] = useState([]);

  const { benefitsToShow } = useSelector((state) => state.loyalty);
  
  const categoryLabel = useMemo(() => 
    user?.LoyaltyCategory__c?.length && user?.LoyaltyCategory__c !== DEFAULT_LOYALTY_CATEGORY ?
    user.LoyaltyCategory__c.split(' - ')[0] : 'Sócio'
  ,[user])

  useEffect(() => {
    document.addEventListener('scroll', doDisplayBar);
    loadLoyaltyItems();
  },[benefitsToShow])

  const doDisplayBar = () => {
    let scrolled = window.scrollY;
    setDisplayBar(scrolled > scrollThreshold ? 'flex' : 'none')
  }

  const loadLoyaltyItems = async () => {
    let items = await userHelper.getLoyaltyItems(limitLoyaltyItems);
    let itemsToShow = items?.filter((item) => benefitsToShow?.includes(item?.Type__c));
    setLoyaltyItems(itemsToShow);
  }

  if(!user?.FirstName){
    return <></>;
  }

  return <StyledFloatingBottomBar config={config} displayBar={displayBar}>
    <div className="bar-container">
      <div className="user-info">
        <Avatar />
        <div className="main-info">
          <Txt typo="tag-button-lg" color={config?.colors?.BRAND.GOLD}>{user?.FirstName}</Txt>
          <Txt typo="caption-md" color={config?.colors?.WHITE.W}>{user?.LoyaltyCategory__c}</Txt>
        </div>
      </div>
      <div className="points">
        <UserPointsTag />
      </div>
      <SocioButton />
      {loyaltyItems?.length ? <div className="offers">
        {loyaltyItems.slice(0, 2).map(item => (
          <div className="offer" key={item.Id}>
            <Image src={item.ImageURL__c} height={18} />
            <Txt typo="tag-button-md" color={config?.colors?.WHITE.W}>{item.Label__c}</Txt>
            <Txt typo="tag-button-md" color={config?.colors?.BRAND.GOLD}>{item.ShortDescription__c}</Txt>
          </div>
        ))}
        <Txt typo="subtitle-sm" className="offer-title">Benefícios {categoryLabel}</Txt>
      </div> : <></>}
    </div>
  </StyledFloatingBottomBar>
}


/* styles */

const StyledFloatingBottomBar = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 40px;
  display: ${props => props.displayBar || 'none'};
  justify-content: center;
  align-items: center;
  z-index: 998;

  // não funciona
  // opacity: ${props => props.displayBar === 'flex' ? 1 : 0};
  // transition: 1s ease-in;

  @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
    bottom: 0;
  }

  .bar-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.config?.colors?.BRAND.DARK80};
    padding: 12px 22px 12px 12px;
    border-radius: 100px;
    gap: 26px;
    filter: drop-shadow(0 0 15px #00022A);

    @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
      border-radius: 0;
      flex: 1;
      flex-direction: row-reverse;
    }
  }


  .user-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
      display: none;
    }

    .main-info{
      display: flex;
      flex-direction: column;
      margin-left: 17px;
      align-items: start;
    }
  }

  .points{
    @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
      display: none;
    }
  }

  .offers{
    display: flex;
    flex-direction: row;
    position: relative;

    @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
      gap: 16px;
      margin-top: 16px;
    }

    .offer-title{
      position: absolute;
      color: ${props => props.config?.colors?.WHITE.W48};
      left: 16px;
      top: -4px;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: .08em;

      @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
        left: 0px;
        top: -16px;
      }
    }

    .offer{
      padding: 12px 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
        padding: 0;
      }

      &:not(:first-of-type){
        border-left: 1px solid ${props => props.config?.colors?.WHITE.W16};
      }

      img{
        margin-right: 12px;
      }

      span{
        margin-right: 4px;
      }
    }
  }
`

export default FloatingBottomBar;