import { createSlice } from '@reduxjs/toolkit';

const menuItemsSlice = createSlice({
  name: 'menuItems',
  initialState: {
    menuItems: [],
  },
  reducers: {
    setRecentMenuItems(state, action) {
      state.menuItems = action.payload.banners;
    },
  },
});

export const { setRecentMenuItems } = menuItemsSlice.actions;

export default menuItemsSlice.reducer;