import { createSlice } from '@reduxjs/toolkit';

const bannersSlice = createSlice({
  name: 'banners',
  initialState: {
    banners: [],
  },
  reducers: {
    setRecentBanners(state, action) {
      state.banners = action.payload.banners;
    },
  },
});

export const { setRecentBanners } = bannersSlice.actions;

export default bannersSlice.reducer;